<template>
  <div class="economics" id="economics">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'economics'
}
</script>

<style scoped>
.economics {
  width: 100%;
  height: 100%;
}
</style>
